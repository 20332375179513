<template>
  <div
    id="order-view"
    class="content"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <div v-if="!isOrderFinish && order" class="container">
      <div class="page-title">
        <img
          class="inline-block"
          src="@/assets/img/confirm-order/logo@2x.png"
          width="186"
          alt="center of mass"
        />
        <span class="inline-block">完成支付</span>
      </div>
      <div id="order-pay">
        <div class="order-header">
          <span
            >订单金额：<span class="active-price" style="font-size: 22px"
              >￥{{ order.real_price / 100 }}</span
            ></span
          >
          <p>
            请于 30 分钟内完成缴费，30
            分钟内未完成支付，您占用的名额将自动释放。
          </p>
        </div>
        <hr class="divider" />
        <div>
          <div>选择支付方式</div>
          <el-radio-group v-model="payType">
            <el-radio v-if="!wechat" class="pay-alipay" label="alipay">
              <i class="chiral fa-zhifubao"></i>
              <span>支付宝</span>
              <div class="recommend">
                <span class="badge badge-warning">推荐</span>
                <span class="text">数亿用户都在用，安全可托付</span>
              </div>
            </el-radio>
            <el-radio class="pay-wechat" label="wechat">
              <i class="chiral fa-wx"></i>
              <span>微信支付</span>
            </el-radio>
          </el-radio-group>
        </div>
        <form
          id="alipay-hidden-form"
          method="post"
          target="_blank"
          enctype="application/x-www-from-urlencoded"
        ></form>
      </div>
      <div class="text-right">
        <el-button
          @click="startPay"
          v-if="order"
          :loading="btnLoading"
          class="pay-btn"
          :disabled="btnDisabled"
          type="primary"
        >
          <slot v-if="!btnDisabled">
            去支付(剩余{{ timestamp | payCountDown }})
          </slot>
          <slot v-else>已超时</slot>
        </el-button>
        <div class="text-right pay-links">
          <p>
            <a class="pay-issue" @click="questionDialog = true"
              >支付遇到问题？</a
            >
          </p>
          <p>
            <a class="pay-backup" @click="deductionDialog = true"
              >您的支付宝或微信账户已经进行了扣款？</a
            >
          </p>
        </div>
      </div>
    </div>
    <div v-if="isOrderFinish && order" class="container">
      <div class="row ldl-finish-order" id="pay-finish">
        <div class="text-center charge-success-title">
          <div>
            <img
              src="@/assets/img/pay/chenggong@2x.png"
              width="37"
              height="37"
              alt=""
            />付款成功
          </div>
        </div>
        <div class="qr-wrap wechat-public-hover qr-official-account-container">
          <div class="qr-official-account-content text-center">
            <img
              alt="二维码"
              class="qrcode"
              src="../../assets/img/qq-code.png"
            />
            <div class="qr-intro-text">
              <h4>订阅微信提醒</h4>
              <p>关注开罗尔官方公众号</p>
            </div>
          </div>
        </div>
        <div class="text-center class-btn">
          <p>
            <a class="btn btn-order-finish btn-primary" @click="goMyClass"
              >去看课</a
            >
          </p>
        </div>
        <div class="qr-wrap wechat-public-hover clearfix" v-if="false">
          <p class="text-center title">
            <span>——</span>
            <b v-if="!isMobile" class="title-text">使用微信扫描下方二维码</b>
            <b v-else class="title-text">保存图片后使用微信扫描下方二维码</b>
            <span>——</span>
          </p>
          <div class="qr-content text-center width-50">
            <img
              src="@/assets/img/pay/customer_service.jpg"
              width="124"
              height="124"
              alt=""
            />
            <div class="qr-intro">
              <h4>添加客服微信</h4>
              <p>及时获取课程资料和最新课程信息</p>
            </div>
          </div>
          <div class="qr-content text-center width-50">
            <img
              src="@/assets/img/pay/official_account.jpg"
              width="124"
              height="124"
              alt=""
            />
            <div class="qr-intro">
              <h4>关注公众号</h4>
              <p>了解更多信息</p>
            </div>
          </div>
        </div>
        <section class="order-notice print-hide">
          <div class="container">
            <div class="live-order-title">直播须知</div>
            <ol style="padding-left: 15px">
              <li>
                电脑上推荐使用「搜狗高速浏览器」「360安全浏览器」「Chrome
                浏览器」进行访问，请不要使用低版本的 IE （IE8 以下）进行访问。
              </li>
              <li>
                手机端推荐下载我们的手机客户端「开罗尔在线」（在各大市场搜索即可下载安装）观看课程或使用手机自带浏览器进行访问。
              </li>
              <li>
                请认真浏览课程介绍，部分课程有教材或题目预习或准备的要求。
              </li>
              <li>
                报名直播课后已经开始直播及直播已经结束的课程恕不退款。未开始的直播课程可以退款，退款方法为：进入我的订单页面找到相应订单，点击退款按钮。
              </li>
              <li>在课程直播开始时间的半小时前即可进入直播页面进行准备。</li>
              <li>
                直播过程难免有部分卡顿现象，可以加我们的反馈QQ群 546189883
                进行反馈。
              </li>
            </ol>
          </div>
        </section>
      </div>
    </div>
    <el-dialog
      custom-class="tips-dialog"
      title="支付遇到问题？"
      :visible.sync="questionDialog"
    >
      <p>请尝试刷新页面或重新进入订单页面进行支付。</p>
      <p>
        如仍有问题请立即联系开罗尔客服：
        <a href="tel:400-0356256">400-0356256</a>进行咨询。
      </p>
    </el-dialog>
    <el-dialog
      custom-class="tips-dialog"
      title="您的支付宝或微信账户已经进行了扣款？"
      :visible.sync="deductionDialog"
    >
      <p>请在订单页面查询该订单是否处于已完成状态</p>
      <p>
        如已扣款但订单仍处于待支付状态，请立即联系开罗尔客服
        <a href="tel:400-0356256">400-0356256</a>进行咨询。
      </p>
    </el-dialog>
    <el-dialog
      custom-class="tips-dialog qr-pay"
      :visible.sync="qrcodeVisible"
      center
      :close-on-press-escape="false"
      :title="
        '请用' +
        (payType === 'alipay' ? '支付宝' : '微信') +
        '扫描二维码以继续支付'
      "
      :close-on-click-modal="false"
      @close="stopQuery"
    >
      <p class="text-center">
        <img :src="qrcodeImg" width="160px" alt="支付二维码" />
      </p>
      <p v-if="payType === 'alipay'">
        （如果您在手机上操作，请截屏后在支付宝上点击识别二维码。）
      </p>
      <p v-else>
        （如果您在手机上操作请截屏后在微信上点击识别二维码，或直接用微信扫码登录开罗尔官网。）
      </p>
      <p class="doubt">
        <i class="chiral fa-wenhao"></i>
        <a href="javascript:;" @click="showQuestion = !showQuestion"
          >支付遇到问题？</a
        >
      </p>
      <p v-if="showQuestion">请尝试刷新页面或重新进入订单页面进行支付。</p>
      <p v-if="showQuestion" class="font-12">
        如仍有问题请立即联系开罗尔客服：
        <a href="400-0356256">400-0356256</a>进行咨询。
      </p>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/plugins/api";
import $ from "jquery";
import { isMobile, isWeChat } from "@/utils/utils";
import pingpp from "pingpp-js";
import qrcode from "qrcode";
import { STATE_MAP, GROUP_ACTIVITY } from "@/utils/constants";
import qs from "qs";

export default {
  name: "OrderIndex",
  data() {
    return {
      loading: true,
      orderNo: "",
      order: "",
      payType: "alipay",
      questionDialog: false,
      deductionDialog: false,
      btnDisabled: false,
      timer: null,
      orderTimer: null,
      timestamp: "",
      isOrderFinish: false,
      btnLoading: false,
      qrcodeImg: "",
      qrcodeVisible: false,
      showQuestion: false,
    };
  },
  filters: {
    payCountDown(time) {
      const mm = Math.floor(time / 60);
      const ss = time % 60;
      return (
        (String(mm).length > 1 ? mm : "0" + mm) +
        ":" +
        (String(ss).length > 1 ? ss : "0" + ss)
      );
    },
  },
  mounted() {
    this.orderNo = this.$route.params.orderNo;
    this.init();
  },
  methods: {
    goLogin() {
      //   this.$showLogin({ loginType: LOGIN_TYPE.LOGIN }, () => {
      //     this.getUserInfo().then((_) => {
      //       this.init();
      //     });
      //   });
    },
    init() {
      if (this.orderNo) {
        this.queryOrderState(true);
      } else {
        this.$router.push({ path: "/404" });
      }
      if (this.wechat) this.payType = "wechat";
    },
    queryOrderState(flag = false) {
      api
        .queryOrder({
          order_no: this.$route.params.orderNo,
          check_charged: 0,
        })
        .then(
          (res) => {
            if (res.data.code === 1) {
              if (flag) {
                this.order = res.data.data;
                if (!this.order) {
                  this.$message.error("错误订单");
                  this.$router.push({ path: "/404" });
                  return false;
                }
                this.price = this.order.real_price;
                this.checkState();
              } else if (
                Number(res.data.data.state) === Number(STATE_MAP.HAS_PAYED)
              ) {
                this.stopQuery();
                this.isOrderFinish = true;
                if (
                  this.order.activity_info &&
                  this.order.activity_type === GROUP_ACTIVITY.GROUP
                ) {
                  this.goGroupActivity(this.order.activity_info);
                } else if (
                  this.order.various &&
                  this.order.various.length > 0
                ) {
                  let activityInfo;
                  let state = this.order.various.some((item) => {
                    let groupState =
                      item.activity_info &&
                      item.activity_type === GROUP_ACTIVITY.GROUP;
                    if (groupState) {
                      activityInfo = item.activity_info;
                    }
                    return groupState;
                  });
                  if (state) {
                    this.goGroupActivity(activityInfo);
                  }
                }
              }
            } else if (res.data.code === 20300) {
              this.goLogin();
            } else {
              this.$message.warning(res.data.msg);
            }
          },
          () => {
            this.handelPayFail(flag);
          }
        );
    },
    handelPayFail(flag) {
      if (!flag) {
        this.$message.error("支付失败");
      }
    },
    goMyClass() {
      let myCourseUrl = {
        开罗尔: "/my-course/course-list?course_type=0",
        质心官网: process.env.VUE_APP_ZHIXIN_OFFICIAL_URL + "/page/mineLives",
        质心灵动量: process.env.VUE_APP_ZHIXIN_LDL_URL + "/mycourse",
      };
      let urlOrigin =
        myCourseUrl[this.order.various[0].course_belong] ||
        "/my-course/course-list?course_type=0";
      window.location.href = urlOrigin;
    },
    startPay() {
      const data = {
        number: this.price,
        order_no: this.orderNo,
        channel: this.getChannel(),
      };
      this.btnLoading = true;
      this.showQuestion = false;
      api.charge(data).then((res) => {
        if (res.data.code === 1) {
          this.handleCreateOrder(res.data);
        } else if (res.data.msg === "没有这个订单") {
          this.$message.warning("登录账号没有该订单，请切换其他账号");
        } else {
          this.$message.warning(res.data.msg);
        }
        this.btnLoading = false;
      });
    },
    handleCreateOrder(data) {
      const wapAliUrl = data.chargeData.credential.alipay_wap;
      const wapUrl = data.chargeData.credential.wx_wap;
      if (wapAliUrl || wapUrl) {
        pingpp.createPayment(data.chargeData, function (result, err) {
          if (err) {
            console.log(err);
          }
          if (result === "success") {
            if (
              this.order.activity_info &&
              this.order.activity_type === GROUP_ACTIVITY.GROUP
            ) {
              this.goGroupActivity(this.order.activity_info);
            } else if (this.order.various.length > 0) {
              let activityInfo;
              let state = this.order.various.some((item) => {
                let groupState =
                  item.activity_info &&
                  item.activity_type === GROUP_ACTIVITY.GROUP;
                if (groupState) {
                  activityInfo = item.activity_info;
                }
                return groupState;
              });
              if (state) {
                this.goGroupActivity(activityInfo);
              }
            }
            this.isOrderFinish = true;
          } else if (result === "cancel") {
            window.location.href =
              process.env.VUE_APP_DOMAIN + "/?utm_source=class";
          }
        });
        return false;
      }
      if (this.payType === "wechat") {
        if (this.wechat) {
          window.location =
            process.env.VUE_APP_ZHIXIN_URL +
            "/Charge/wxpay/" +
            this.orderNo +
            "/" +
            this.price;
        } else {
          this.qRCodePay(data);
        }
      } else if (this.payType === "alipay") {
        if (!this.isMobile) {
          this.qRCodePay(data);
        } else {
          this.derictToAli({
            channel: this.getChannel(),
            order_no: this.orderNo,
            quark: this.price,
          });
        }
      }
    },
    qRCodePay(data) {
      const orderUrl =
        data.chargeData.credential.wx_pub_qr ||
        data.chargeData.credential.alipay_qr;
      if (!orderUrl) return;
      qrcode.toDataURL(orderUrl, { width: 156 }, (err, url) => {
        if (err) throw err;
        this.qrcodeImg = url;
      });
      this.qrcodeVisible = true;
      this.orderTimer = setInterval(() => {
        this.isOrderFinish = false;
        this.queryOrderState();
      }, 2000);
    },
    derictToAli(data) {
      const $form = $("#alipay-hidden-form");
      let url = "";
      data.forEach(data, (item, index) => {
        var m = '<input type="hidden" name="' + index + '"/>';
        $("#alipay-hidden-form").append(m);
        $("#alipay-hidden-form " + 'input[name="' + index + '"]').val(item);
        url = url + "&" + index + "=" + item;
      });
      $form.attr("action", "/v1/Charge/redirectAlipay?" + url.slice(1));
      $form.submit();
    },
    stopQuery() {
      this.qrcodeVisible = false;
      clearInterval(this.orderTimer);
    },
    getChannel() {
      let channel = this.payType === "alipay" ? "alipay_qr" : "wx_pub_qr";
      if (this.payType === "wechat" && !this.wechat && this.isMobile) {
        channel = "wx_wap";
      }
      // alipay_wap
      if (this.payType === "alipay" && !this.wechat && this.isMobile) {
        channel = "alipay_wap";
      }
      return channel;
    },
    judgeAllOrders(obj) {
      if (
        Number(obj.state) === Number(STATE_MAP.HAS_PAYED) ||
        Number(obj.state) === Number(STATE_MAP.HAS_TRANSFERED)
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkState() {
      if (Number(this.order.product_type) === 10) {
        this.isOrderFinish = this.order.various.every(this.judgeAllOrders);
      } else if (
        Number(this.order.state) === Number(STATE_MAP.HAS_PAYED) ||
        Number(this.order.state) === Number(STATE_MAP.HAS_TRANSFERED)
      ) {
        this.isOrderFinish = true;
      } else {
        this.isOrderFinish = false;
      }
      if (!this.isOrderFinish) {
        this.startTimer();
      }
      this.loading = false;
    },
    startTimer() {
      const payEndTime = Number(this.order.order_at) + 30 * 60;
      this.timestamp = Math.floor(payEndTime) - Math.floor($.now() / 1000);
      if (Number(this.timestamp) < 1) {
        this.btnDisabled = true;
      }
      this.timer = window.setInterval(() => {
        if (Number(this.timestamp) < 1) {
          this.btnDisabled = true;
          clearInterval(this.timer);
        } else {
          this.timestamp -= 1;
        }
      }, 1000);
    },
    goGroupActivity(activityInfo) {
      let urlSearch = qs.parse(window.location.search.substring(1));
      if (activityInfo.group_id) {
        this.$router.push({
          path: "/group-activity/" + activityInfo.group_id,
          query: Object.assign({ isPaided: true }, urlSearch),
        });
      }
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
    wechat() {
      return isWeChat();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.orderTimer);
  },
};
</script>
<style lang="scss" scoped>
#order-view {
  text-align: left;
  padding: 30px 0;
  min-height: 75vh;
  :deep .el-dialog__header {
    display: inline-block;
  }
  .order-notice {
    //   border-top: 1px solid rgba(51, 51, 51, 0.1);
    padding-bottom: 40px;
    .live-order-title {
      font-size: 20px;
      font-weight: 500;
      color: #333;
      line-height: 28px;
      margin-bottom: 40px;
    }
    li {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 20px;
      margin-bottom: 10px;
      list-style: decimal;
    }
  }
  .page-title {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 40px;
    color: rgba(51, 51, 51, 0.9);

    .inline-block {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }
  }

  #order-pay {
    color: #6f7480;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    padding: 0 32px;
    border: 1px solid rgba(51, 51, 51, 0.1);

    .order-header {
      margin: 30px 0 20px;

      .active-price {
        font-size: 22px;
        color: #ffa000;
      }

      p {
        color: rgba(239, 77, 60, 0.8);
        font-size: 12px;
      }
    }

    .divider {
      border: 0;
      border-top: 1px solid rgba(51, 51, 51, 0.1);
      margin: 20px 0;
    }

    .el-radio {
      display: block;
      height: 64px;
      line-height: 63px;

      i {
        font-size: 32px;
        margin-right: 8px;
        vertical-align: middle;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.9);
      }

      &.pay-alipay {
        i {
          color: #00a0e9;
        }

        .recommend {
          display: inline-block;
          margin-left: 15px;
          line-height: 1;

          .badge-warning {
            font-size: 12px;
            font-weight: 400;
            color: #ffa000;
            border-radius: 2px;
            background: rgba(255, 160, 0, 0.11);
            padding: 3px 7px 2px;
            line-height: 1;
            text-align: center;
            margin-right: 10px;
          }

          .text {
            font-size: 12px;
            color: rgba(51, 51, 51, 0.6);
          }
        }
        @media screen and (max-width: 767px) {
          div.recommend {
            vertical-align: middle;
            .badge-warning {
              width: 32px;
              display: block;
            }
          }
        }
      }

      &.pay-wechat {
        i {
          color: #09bb07;
        }
      }
    }
  }

  .pay-btn {
    width: 230px;
    height: 48px;
    margin-top: 40px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
  }

  .pay-links {
    P {
      margin-top: 10px;

      a {
        font-size: 12px;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.7);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .tips-dialog {
    width: 90%;
    max-width: 600px;

    .el-dialog__title {
      font-weight: 500;
    }
    a {
      color: $purple-color;
    }
  }

  #pay-finish {
    color: #333;
    margin-top: -30px;
    padding-top: 30px;

    .container {
      max-width: 830px;
    }

    .class-btn {
      margin-top: 45px;
      margin-bottom: 75px;
    }

    .charge-success-title {
      img {
        margin-right: 25px;
        vertical-align: middle;
      }

      font-size: 20px;
      font-weight: 500;
      color: $purple-color;
      line-height: 28px;
      padding-top: 30px;
      margin-bottom: 32px;
    }

    .wechat-public-hover {
      border-radius: 4px;
      word-wrap: break-word;
      vertical-align: middle;
      max-width: 562px;
      padding: 32px 0;
      margin: 0 auto;
      border: 1px solid rgba(51, 51, 51, 0.1);
      color: #333;

      h4 {
        font-size: 18px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        margin-top: 0;
      }

      img {
        display: inline-block;
        width: 124px;
        height: 124px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }

      .qr-intro-text {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        margin-left: 40px;
      }

      .title-text {
        margin: 0 16px;
        font-weight: 500;
      }

      @media screen and (max-width: 767px) {
        width: 98%;
      }
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 18px;
      p {
        margin-bottom: 10px;
      }
    }

    a {
      background: #6c5ce7;
      color: white;
      width: 120px;
      height: 36px;
      font-size: 16px;
      padding: 0;
      line-height: 36px;
      font-weight: bold;
      cursor: pointer;
    }

    .qr-wrap {
      p {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.6);
        line-height: 14px;

        &.title {
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
          line-height: 21px;

          span {
            color: rgba(51, 51, 51, 0.16);
          }
        }
      }

      .qr-content {
        margin-top: 32px;
      }

      .qr-intro {
        margin-left: unset;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 14px;
        display: block;
      }

      h4 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    .width-50 {
      width: 50%;
      float: left;
    }

    @media screen and (max-width: 767px) {
      div.class-btn {
        margin-bottom: 25px;
      }
      div.qr-wrap {
        border: none;
      }
      a.btn-order-finish {
        margin-bottom: 0;
      }
      .width-50 {
        width: 100%;
      }
    }
  }

  .qr-pay {
    img {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
      &.font-12 {
        font-size: 12px;
      }
      &.doubt a,
      &.doubt i {
        color: #91a4c2;
        margin-right: 5px;
      }
    }
  }
  .qr-official-account-container {
    border-radius: 4px;
    word-wrap: break-word;
    vertical-align: middle;
    max-width: 562px;
    padding: 32px 0;
    margin: 0 auto;
    border: 1px solid rgba(51, 51, 51, 0.1);
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;

    .qr-official-account-content {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }
    .qrcode {
      width: 124px;
      height: 124px;
    }
    .qr-intro {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      margin-left: 40px;
      h4 {
        font-size: 18px;
        font-weight: 500;
        color: #333;
        line-height: 25px;
        margin-top: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
